import React, { Component } from 'react';
import { Grid, Typography, Card, WithStyles, withStyles, Theme, Button, CircularProgress, Box } from '@material-ui/core';
import { Link, RouteComponentProps, withRouter, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { myMenuOperations } from "../../store/myMenu";
import { menuOperations } from '../../store/menu';
import { IGlobalState, globalOperations } from "../../store/global";

import IMyMenu from '../../interfaces/IMyMenu';
import moment from "moment"
import { MoreVert } from '@material-ui/icons';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const styles = (theme: Theme) => ({
    root: {
        display: 'flex',
    },
    details: {
        display: 'flex',
    },
    editButton: {
        backgroundColor: "#FFF",
        color: "#007D7A",
        border: "2px solid #007D7A",
        fontWeight: "bold" as "bold",
        "&:hover": {
            backgroundColor: "#007D7A",
            border: "2px solid #007D7A",
            color: "#FFF"
        }
    },
    horizontallLine: {
        borderTop: "1px solid gray",
        width: "100%",
        marginBottom: theme.spacing(1)
    },
    text: {
        color: "#007D7A",
        fontWeight: "bold" as "bold",
    },
    label: {
        color: "black",
        fontWeight: "bold" as "bold",
    },
    descriptionText: {
        marginBottom: theme.spacing(2)
    },
    smallText: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        fontSize: 11,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9
        }
    },
    gridPadding: {
        padding: theme.spacing(2)
    },
    contentPadding: {
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(2)
    },
    imagePadding: {
        paddingTop: theme.spacing(3),
        marginBottom: theme.spacing(1)
    },
    img: {
        maxWidth: 170,
        width: "100%",
        height: "auto",
        paddingBottom: theme.spacing(2)
    },
    newMenu: {
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: 10
    },
    edit: {
        cursor: "pointer",
        color: "#A9A9A9",
        "&:hover": {
            color: "#007D7A",
        }
    },
    title: {
        wordBreak: "break-word"
    }
});

interface IMyMenuListProps {
    getMenus: any,
    myMenu: any,
    global: IGlobalState,
    getUser: any,
    changeMyMenuTitle: any,
    deleteMyMenu: any,
    getMenuData: any
}

interface IMyMenuListState {
    anchorElement: any;
    open: boolean;
    menu: any;
    isMobileApp: boolean;
    hasLoaded: boolean;
}

class MyMenuList extends Component<WithStyles & IMyMenuListProps & RouteComponentProps, IMyMenuListState> {
    constructor(props: any) {
        super(props);

        this.state = {
            anchorElement: null,
            open: false,
            menu: null,
            isMobileApp: false,
            hasLoaded: false
        };
    }

    myInterval: any = null;

    componentDidMount() {
        this.reloadIfRequired();
        this.myInterval=setInterval(this.reloadIfRequired, 10000);
        //this.props.getMenus();
        this.setResponsiveness();
        window.addEventListener("resize", this.setResponsiveness);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setResponsiveness);
        if (this.myInterval) {
            clearInterval(this.myInterval);
        }
    }

    setResponsiveness = () => {
        return window.innerWidth < 900
            ? this.setState((prevState) => ({ ...prevState, isMobileApp: true }))
            : this.setState((prevState) => ({ ...prevState, isMobileApp: false }));
    };

    handleSelectMenu = (menu: IMyMenu) => {
        this.props.getMenuData(menu.id).then(() => {
            sessionStorage.setItem('formatId', menu.formatId.toString());
            this.props.history.push("/menu-builder");
        });
    }

    reloadIfRequired = () => {
        this.props.getMenus();
    }

    handleChangeMenuTitle = () => {
        this.handleCloseMenu();
        this.props.changeMyMenuTitle(this.state.menu);
    }

    handleDeleteMenu = () => {
        this.handleCloseMenu();
        this.props.deleteMyMenu(this.state.menu);
    }

    handleMenuClick = (event: any, menu: IMyMenu) => {
        this.setState({ anchorElement: event.currentTarget, open: true, menu: menu });
    };

    handleCloseMenu = () => {
        this.setState({ anchorElement: null, open: false, menu: null });
    };

    render() {
        const { classes, myMenu } = this.props;
        const { data, loading } = myMenu;

        let showLoading = loading && !this.state.hasLoaded;
        if (!loading && !this.state.hasLoaded) {
            this.setState((prevState) => ({ ...prevState, hasLoaded: true }));
        }

        return (showLoading ?
            <Grid style={{ flex: "1" }} container justifyContent="center" alignItems="center">
                <Grid item>
                    <CircularProgress />
                </Grid>
            </Grid>
            :
            <Grid container spacing={3}>
                {data.length === 0 && <Redirect to="choose-style" />}
                {data.map((menu: IMyMenu) => (
                    <Grid item xs={12} md={4} sm={6} key={menu.id}>
                        <Card raised={true} className={classes.root} id={"" + menu.id}>
                            <Grid container className={classes.gridPadding}>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={11}>
                                            <Typography variant="h5" className={classes.title}>
                                                {menu.title}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={1}>
                                            <div>
                                                <MoreVert aria-controls="simple-menu" aria-haspopup="true" className={classes.edit} onClick={(e) => this.handleMenuClick(e, menu)} />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={5} className={classes.imagePadding}>
                                    {menu.imageValid ? <img src={menu.imgUrl} className={classes.img} alt="menu preview" /> : <img src={`./assets/styles/preview/preview_creating.png`} className={classes.img} alt="menu preview" />}

                                    <Button variant="contained" className={classes.editButton} onClick={() => this.handleSelectMenu(menu)}>Edit</Button>
                                </Grid>
                                <Grid item xs={7} className={classes.contentPadding}>
                                    <Typography variant="body2">
                                        {/*         TODO*/}
                                        <span className={classes.label}> Style: </span><span className={classes.text}>{menu.style}</span><br></br>

                                        {/*  Chosen size: </span><span className={classes.text}>{menu.size}</span>*/}
                                    </Typography>
                                    <div className={classes.horizontallLine} />
                                    <Typography variant="body2" className={classes.descriptionText}>
                                        {menu.availableFormats.reduce((prev, curr, i) => {
                                            return `${prev}${i == 0 ? "" : ", "}${curr.title}`;
                                        }, "")}
                                        {/*           {menu.sizeDescription}*/}
                                    </Typography>

                                    <div className={classes.smallText}>
                                        Created {moment(menu.created).format("DD/MM/YYYY HH:mm")}<br />
                                        Last modified {menu.modified ? moment(menu.modified).format("DD/MM/YYYY HH:mm") : "N/A"}
                                    </div>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                ))}
                <Menu
                    id="simple-menu"
                    anchorEl={this.state.anchorElement}
                    keepMounted
                    open={this.state.open}
                    onClose={this.handleCloseMenu}>
                    <MenuItem id="edit" data-menu="edit" onClick={this.handleChangeMenuTitle}>Edit Title</MenuItem>
                    <MenuItem id="delete" data-menu="delete " onClick={this.handleDeleteMenu}>Delete Design</MenuItem>
                </Menu>
            </Grid>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        myMenu: state.myMenu,
        global: state.global,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getMenus: () => dispatch(myMenuOperations.getMyMenus()),
        getMenuData: (id: number) => dispatch(menuOperations.getMenuData(id)),
        getUser: () => dispatch(globalOperations.getMe()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(MyMenuList)));