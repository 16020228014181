﻿import { FormatQuantityOption } from "../components/printPal/PrintPalFormatsOptions";
import { PreviewContext } from "../store/menu";
import { FormatModelPartial } from "../pages/PlaceOrderWithFormats";

export const getCacheKey = () => {

    return 10069;

    return 1;
}

export const getOrderDescription = (quantities: FormatQuantityOption[], wobblerArmsAmount: number, formats: FormatModelPartial[], magic8Stickers?: boolean) => {
    //console.log("Getting description from", quantities, wobblerArmsAmount, formats);
    let description = "";
    let count = 0;
    for(let i =0; i < quantities.length; i++){
        let item = quantities[i];
        if(item.quantity < 1){
            //console.log("Quantity less than 1, skipping");
            continue;
        }
        let format = formats.find((f) => f.id == item.formatId);
        if(!format){
            console.log("Cannot find format id of", item.formatId, "in ", formats);
            continue;
        }
        description = description + `${count == 0 ? "" : ", "}${item.quantity} x ${format.title} ${magic8Stickers ? "M8" : ""}`;
        //console.log(description);
        count++;
    }

    description = description + (wobblerArmsAmount > 0 ? `, ${wobblerArmsAmount} x Wobbler Arms` : "");

    return description;
}

const randomId = (type: string) => {
    return type + (Math.random() * 16).toString(16).toLowerCase().replace(".", "");
}

export const formatPrice = (price?: number, hideCurrencySymbol?: boolean, hideTrailingZeros?: boolean) => {
    if(price == undefined){
        return 0;
    }
    return `${hideCurrencySymbol ? "" : "£"}${hideTrailingZeros ? parseFloat(price.toFixed(2)) : price?.toFixed(2)}`;
}

export const getScaledPosition = (scale: number, translationPosition?: TranslationPosition): TranslationPosition => {

    const { height, left, top, width, z } = translationPosition ?? {
        height: 0, 
        left: 0,
        top: 0,
        width: 0,
        z: 1
    };

    return {
        height: height * scale,
        left: left * scale,
        top: top * scale,
        width: width * scale,
        z
    }
}

export const getBackground = (name: string, format: string, context: PreviewContext) => {
    return `${name}-${format}-${context}.png`;
}

export const getBackgroundGradient = (name: string, format: string) => {
    return `/assets/mealdeal1/BV-MD-${name}GRAD-${format}-PRINT.png`
}

export const getMainProducts = () => {
    return [
        {
            "name": "2 Southern Fried Chicken",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-2 Southern Fried Chicken-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Savoury",
            "src": "/assets/products/BV-MD-SNACK-2 Southern Fried Chicken-RIGHT.png"
        },
        {
            "name": "3 Southern Fried Chicken",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-3 Southern Fried Chicken-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Savoury",
            "src": "/assets/products/BV-MD-SNACK-3 Southern Fried Chicken-RIGHT.png"
        },
        {
            "name": "Americano",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Americano-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Hot Drinks",
            "src": "/assets/products/BV-MD-SNACK-Americano-RIGHT.png"
        },
        {
            "name": "Bacon Breakfast Bap",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Bacon Breakfast Bap-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Breakfast",
            "src": "/assets/products/BV-MD-SNACK-Bacon Breakfast Bap-RIGHT.png"
        },
        {
            "name": "Bacon Egg Sausage Breakfast Bap",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Bacon Egg Sausage Breakfast Bap-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Breakfast",
            "src": "/assets/products/BV-MD-SNACK-Bacon Egg Sausage Breakfast Bap-RIGHT.png"
        },
        {
            "name": "Baguette",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Baguette-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Sandwich",
            "src": "/assets/products/BV-MD-SNACK-Baguette-RIGHT.png"
        },
        {
            "name": "Banana",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Banana-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Fruit/Nuts",
            "src": "/assets/products/BV-MD-SNACK-Banana-RIGHT.png"
        },
        {
            "name": "Barratt Refresher Softies",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Barratt Refresher Softies-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Sweets",
            "src": "/assets/products/BV-MD-SNACK-Barratt Refresher Softies-RIGHT.png"
        },
        {
            "name": "Beef Hula Hoops",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Beef Hula Hoops-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Beef Hula Hoops-RIGHT.png"
        },
        {
            "name": "Boiled Egg",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Boiled egg-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Savoury",
            "src": "/assets/products/BV-MD-SNACK-Boiled egg-RIGHT.png"
        },
        {
            "name": "Bowl of Cereal",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Bowl of Cereal 2-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Breakfast",
            "src": "/assets/products/BV-MD-SNACK-Bowl of Cereal 2-RIGHT.png"
        },
        {
            "name": "Bowl of Wedges",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Bowl Wedges-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Savoury",
            "src": "/assets/products/BV-MD-SNACK-Bowl Wedges-RIGHT.png"
        },
        {
            "name": "Breakfast Bowl",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Breakfast Bowl-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Breakfast",
            "src": "/assets/products/BV-MD-SNACK-Breakfast Bowl-RIGHT.png"
        },
        {
            "name": "Buchanans Chocolate Caramels",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Buchanans Chocolate Caramels-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Buchanans Chocolate Caramels-RIGHT.png"
        },
        {
            "name": "Burger",
            "shortDesc": "Mains",
            "longDesc": "Mains",
            "imageUrl": "BV-MD-SNACK-Burger-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Burger",
            "src": "/assets/products/BV-MD-SNACK-Burger-RIGHT.png"
        },
        {
            "name": "Burts Lightly Sea Salted Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Burts Lightly Sea Salted-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Burts Lightly Sea Salted-RIGHT.png"
        },
        {
            "name": "Burts Mature Cheddar & Onion Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Burts Mature Cheddar & Onion-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Burts Mature Cheddar & Onion-RIGHT.png"
        },
        {
            "name": "Burts Sea Salt & Malt Vinegar Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Burts Sea Salt & Malt Vinegar-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Burts Sea Salt & Malt Vinegar-RIGHT.png"
        },
        {
            "name": "Cadbury Dairy Milk",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Cadbury Dairy Milk-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Cadbury Dairy Milk-RIGHT.png"
        },
        {
            "name": "Cadbury Dairy Milk Caramel",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Cadbury Dairy Milk Caramel-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Cadbury Dairy Milk Caramel-RIGHT.png"
        },
        {
            "name": "Cadbury Dairy Milk Marvellous Creations",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Cadbury Dairy Milk Marvellous Creations-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Cadbury Dairy Milk Marvellous Creations-RIGHT.png"
        },
        {
            "name": "Cadbury Double Decker",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Cadbury Double Decker-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Cadbury Double Decker-RIGHT.png"
        },
        {
            "name": "Cadbury Flake",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Cadbury Flake-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Cadbury Flake-RIGHT.png"
        },
        {
            "name": "Cadbury Twirl",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Cadbury Twirl-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Cadbury Twirl-RIGHT.png"
        },
        {
            "name": "Cadbury Wispa",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Cadbury Wispa-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Cadbury Wispa-RIGHT.png"
        },
        {
            "name": "Carrot Cream Soup",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Carrot Cream Soup-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Soup",
            "src": "/assets/products/BV-MD-SNACK-Carrot Cream Soup-RIGHT.png"
        },
        {
            "name": "Carrot Sticks (Right)",
            "shortDesc": "Snack",
            "longDesc": "Snack",
            "imageUrl": "BV-MD-MAINS-CarrotSticks-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Fruit/Nuts",
            "src": "/assets/products/BV-MD-MAINS-CarrotSticks-RIGHT.png"
        },
        {
            "name": "Cereal Bar",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Cereal Bar-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Cake",
            "src": "/assets/products/BV-MD-SNACK-Cereal Bar-RIGHT.png"
        },
        {
            "name": "Cheese Sandwich",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Cheese Sandwich-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Sandwich",
            "src": "/assets/products/BV-MD-SNACK-Cheese Sandwich-RIGHT.png"
        },
        {
            "name": "Chewy Sweets",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Chewy Sweets-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Sweets",
            "src": "/assets/products/BV-MD-SNACK-Chewy Sweets-RIGHT.png"
        },
        {
            "name": "Chicken Burger",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Chicken Burger-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Burger",
            "src": "/assets/products/BV-MD-SNACK-Chicken Burger-RIGHT.png"
        },
        {
            "name": "Chicken Caesar Salad",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Chicken Caesar Salad-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Salad",
            "src": "/assets/products/BV-MD-SNACK-Chicken Caesar Salad-RIGHT.png"
        },
        {
            "name": "Chicken Katsu Curry",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Chicken Katsu Curry-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Curry",
            "src": "/assets/products/BV-MD-SNACK-Chicken Katsu Curry-RIGHT.png"
        },
        {
            "name": "Chicken Mayo Sandwich",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Chicken Mayo Sandwich-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Sandwich",
            "src": "/assets/products/BV-MD-SNACK-Chicken Mayo Sandwich-RIGHT.png"
        },
        {
            "name": "Chicken Noodle Soup",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Chicken Noodle Soup-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Soup",
            "src": "/assets/products/BV-MD-SNACK-Chicken Noodle Soup-RIGHT.png"
        },
        {
            "name": "Chicken Wrap 1",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Chicken Wrap 1-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Main",
            "src": "/assets/products/BV-MD-SNACK-Chicken Wrap 1-RIGHT.png"
        },
        {
            "name": "Chicken Wrap 2",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Chicken Wrap 2-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Main",
            "src": "/assets/products/BV-MD-SNACK-Chicken Wrap 2-RIGHT.png"
        },
        {
            "name": "Chocolate Cake",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Chocolate Cake-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Cake",
            "src": "/assets/products/BV-MD-SNACK-Chocolate Cake-RIGHT.png"
        },
        {
            "name": "Chocolate Cookie",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Chocolate Cookie-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Cookie",
            "src": "/assets/products/BV-MD-SNACK-Chocolate Cookie-RIGHT.png"
        },
        {
            "name": "Clementine",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Clementine-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Fruit/Nuts",
            "src": "/assets/products/BV-MD-SNACK-Clementine-RIGHT.png"
        },
        {
            "name": "Coleslaw",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Coleslaw-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Savoury",
            "src": "/assets/products/BV-MD-SNACK-Coleslaw-RIGHT.png"
        },
        {
            "name": "Couscous",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Couscous-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Salad",
            "src": "/assets/products/BV-MD-SNACK-Couscous-RIGHT.png"
        },
        {
            "name": "Crackers",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Crackers-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Cookie",
            "src": "/assets/products/BV-MD-SNACK-Crackers-RIGHT.png"
        },
        {
            "name": "Crunchie",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Crunchie-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Crunchie-RIGHT.png"
        },
        {
            "name": "Doritos Cool Original",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Doritos Cool Original-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Doritos Cool Original-RIGHT.png"
        },
        {
            "name": "Doritos Tangy Cheese",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Doritos Tangy Cheese-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Doritos Tangy Cheese-RIGHT.png"
        },
        {
            "name": "Eat Real Tomato and Basil Lentil Chips",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Eat_Real_Lentil-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Eat_Real_Lentil-RIGHT.png"
        },
        {
            "name": "Egg Breakfast Bap",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Egg Breakfast Bap-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Breakfast",
            "src": "/assets/products/BV-MD-SNACK-Egg Breakfast Bap-RIGHT.png"
        },
        {
            "name": "Egg Mayo Sandwich",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Egg Mayo Sandwich-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Sandwich",
            "src": "/assets/products/BV-MD-SNACK-Egg Mayo Sandwich-RIGHT.png"
        },
        {
            "name": "Falafel",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Falafel-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Salad",
            "src": "/assets/products/BV-MD-SNACK-Falafel-RIGHT.png"
        },
        {
            "name": "Feta Salad Bowl",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Feta Salad Bowl-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Salad",
            "src": "/assets/products/BV-MD-SNACK-Feta Salad Bowl-RIGHT.png"
        },
        {
            "name": "Fruit Sweets",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Fruit Sweets-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Sweets",
            "src": "/assets/products/BV-MD-SNACK-Fruit Sweets-RIGHT.png"
        },
        {
            "name": "Fruit-Tella",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Fruit-Tella-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Sweets",
            "src": "/assets/products/BV-MD-SNACK-Fruit-Tella-RIGHT.png"
        },
        {
            "name": "Frys Chocolate Cream",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Frys Chocolate Cream-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Frys Chocolate Cream-RIGHT.png"
        },
        {
            "name": "Frys Peppermint Cream",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Frys Peppermint Cream-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Frys Peppermint Cream-RIGHT.png"
        },
        {
            "name": "Frys Turkish Delight",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Frys Turkish Delight-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Frys Turkish Delight-RIGHT.png"
        },
        {
            "name": "Galaxy Ripple",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Galaxy Ripple-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Galaxy Ripple-RIGHT.png"
        },
        {
            "name": "Galaxy Smooth Milk",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Galaxy Smooth Milk-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Galaxy Smooth Milk-RIGHT.png"
        },
        {
            "name": "Garlic Bread",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Garlic Bread-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Savoury",
            "src": "/assets/products/BV-MD-SNACK-Garlic Bread-RIGHT.png"
        },
        {
            "name": "Green apple",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Green apple-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Fruit/Nuts",
            "src": "/assets/products/BV-MD-SNACK-Green apple-RIGHT.png"
        },
        {
            "name": "Green Avocado Salad",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Green Avocado Salad-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Salad",
            "src": "/assets/products/BV-MD-SNACK-Green Avocado Salad-RIGHT.png"
        },
        {
            "name": "Grilled Sandwich",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Grilled Sandwich-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Sandwich",
            "src": "/assets/products/BV-MD-SNACK-Grilled Sandwich-RIGHT.png"
        },
        {
            "name": "Ham Roll",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Ham Roll-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Sandwich",
            "src": "/assets/products/BV-MD-SNACK-Ham Roll-RIGHT.png"
        },
        {
            "name": "Ham Sandwich",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Ham Sandwich-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Sandwich",
            "src": "/assets/products/BV-MD-SNACK-Ham Sandwich-RIGHT.png"
        },
        {
            "name": "Ham, Cheese & Tomato Sandwich",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Ham, Cheese & Tomato Sandwich-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Sandwich",
            "src": "/assets/products/BV-MD-SNACK-Ham, Cheese & Tomato Sandwich-RIGHT.png"
        },
        {
            "name": "Ham, Cheese & Tomato Thick  Sandwich",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Ham, Cheese & Tomato Thick  Sandwich-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Sandwich",
            "src": "/assets/products/BV-MD-SNACK-Ham, Cheese & Tomato Thick  Sandwich-RIGHT.png"
        },
        {
            "name": "Haribo Starmix",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Haribo Starmix-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Sweets",
            "src": "/assets/products/BV-MD-SNACK-Haribo Starmix-RIGHT.png"
        },
        {
            "name": "Haribo Tanfastics",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Haribo Tanfastics-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Sweets",
            "src": "/assets/products/BV-MD-SNACK-Haribo Tanfastics-RIGHT.png"
        },
        {
            "name": "Houmous",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Houmous-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Salad",
            "src": "/assets/products/BV-MD-SNACK-Houmous-RIGHT.png"
        },
        {
            "name": "Jacket Potato",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Jacket Potato-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Main",
            "src": "/assets/products/BV-MD-SNACK-Jacket Potato-RIGHT.png"
        },
        {
            "name": "Jacket Potato with beans",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Jacket Potato with beans-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Savoury",
            "src": "/assets/products/BV-MD-SNACK-Jacket Potato with beans-RIGHT.png"
        },
        {
            "name": "Jacket Potato with cheese",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Jacket Potato with cheese-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Savoury",
            "src": "/assets/products/BV-MD-SNACK-Jacket Potato with cheese-RIGHT.png"
        },
        {
            "name": "Jacket Potato with tuna",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Jacket Potato with tuna-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Savoury",
            "src": "/assets/products/BV-MD-SNACK-Jacket Potato with tuna-RIGHT.png"
        },
        {
            "name": "Kent Crisps Cheese and Onion",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Kent Crisps Cheese and Onion-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Kent Crisps Cheese and Onion-RIGHT.png"
        },
        {
            "name": "Kent Crisps Salt and Vinegar",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Kent Crisps Salt and Vinegar-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Kent Crisps Salt and Vinegar-RIGHT.png"
        },
        {
            "name": "Kettle Lightly Salted Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Kettle Lightly Salted Crisps-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Kettle Lightly Salted Crisps-RIGHT.png"
        },
        {
            "name": "Kettle Sea Salt & Balsamic Vinegar Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Kettle Sea Salt & Balsamic Vinegar Crisps-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Kettle Sea Salt & Balsamic Vinegar Crisps-RIGHT.png"
        },
        {
            "name": "Kit Kat",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Kit Kat-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Kit Kat-RIGHT.png"
        },
        {
            "name": "Kit Kat Chunky",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Kit Kat Chunky-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Kit Kat Chunky-RIGHT.png"
        },
        {
            "name": "Latte",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Latte-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Hot Drinks",
            "src": "/assets/products/BV-MD-SNACK-Latte-RIGHT.png"
        },
        {
            "name": "Lion Bar",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Lion Bar-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Lion Bar-RIGHT.png"
        },
        {
            "name": "Listers Cheese & Onion Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Listers Cheese & Onion Crisps-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Listers Cheese & Onion Crisps-RIGHT.png"
        },
        {
            "name": "Listers Sea Salt & Vinegar Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Listers Sea Salt & Vinegar Crisps-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Listers Sea Salt & Vinegar Crisps-RIGHT.png"
        },
        {
            "name": "Listers Sea Salt Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Listers Sea Salt Crisps-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Listers Sea Salt Crisps-RIGHT.png"
        },
        {
            "name": "Listers Sea Steak & Ale Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Listers Sea Steak & Ale Crisps-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Listers Sea Steak & Ale Crisps-RIGHT.png"
        },
        {
            "name": "Listers Sweet Chilli Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Listers Sweet Chilli Crisps-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Listers Sweet Chilli Crisps-RIGHT.png"
        },
        {
            "name": "Mars Bar (Right)",
            "shortDesc": "Snack",
            "longDesc": "Snack",
            "imageUrl": "BV-MD-SNACK-MarsBar-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-MarsBar-RIGHT.png"
        },
        {
            "name": "Mars Maltesers",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Mars Maltesers-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Mars Maltesers-RIGHT.png"
        },
        {
            "name": "Maynards Bassetts Jelly Babies",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Maynards Bassetts Jelly Babies-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Sweets",
            "src": "/assets/products/BV-MD-SNACK-Maynards Bassetts Jelly Babies-RIGHT.png"
        },
        {
            "name": "McCoys Ridge Flamed Grill Steak Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-McCoys Ridge Flamed Grill Steak Crisps-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-McCoys Ridge Flamed Grill Steak Crisps-RIGHT.png"
        },
        {
            "name": "McCoys Ridge Salted Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-McCoys Ridge Salted Crisps-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-McCoys Ridge Salted Crisps-RIGHT.png"
        },
        {
            "name": "Meatballs",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Meatballs-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Main",
            "src": "/assets/products/BV-MD-SNACK-Meatballs-RIGHT.png"
        },
        {
            "name": "Medium bowl fries",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Medium bowl fries-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Fries",
            "src": "/assets/products/BV-MD-SNACK-Medium bowl fries-RIGHT.png"
        },
        {
            "name": "Milky Bar",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Milky Bar-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Milky Bar-RIGHT.png"
        },
        {
            "name": "Muffin",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Muffin-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Cake",
            "src": "/assets/products/BV-MD-SNACK-Muffin-RIGHT.png"
        },
        {
            "name": "Nachos Bowl",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Nachos Bowl -RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Savoury",
            "src": "/assets/products/BV-MD-SNACK-Nachos Bowl -RIGHT.png"
        },
        {
            "name": "Nachos Plate 1",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Nachos Plate 1-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Savoury",
            "src": "/assets/products/BV-MD-SNACK-Nachos Plate 1-RIGHT.png"
        },
        {
            "name": "Nachos Plate 2",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Nachos Plate 2-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Savoury",
            "src": "/assets/products/BV-MD-SNACK-Nachos Plate 2-RIGHT.png"
        },
        {
            "name": "Nestle Aero",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Nestle Aero-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Nestle Aero-RIGHT.png"
        },
        {
            "name": "Nestle Smarties",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Nestle Smarties-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Nestle Smarties-RIGHT.png"
        },
        {
            "name": "Nestle Yorkie",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Nestle Yorkie-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Nestle Yorkie-RIGHT.png"
        },
        {
            "name": "Nik Naks Nice ‘n’ Spicy",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Nik Naks NiceN Spicy-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Nik Naks NiceN Spicy-RIGHT.png"
        },
        {
            "name": "Noodle Bowl",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Noodle bowl-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Main",
            "src": "/assets/products/BV-MD-SNACK-Noodle bowl-RIGHT.png"
        },
        {
            "name": "Nuts",
            "shortDesc": "Snack",
            "longDesc": "Snack",
            "imageUrl": "BV-MD-SNACK-Nuts-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Fruit/Nuts",
            "src": "/assets/products/BV-MD-SNACK-Nuts-RIGHT.png"
        },
        {
            "name": "Oatmeal Breakfast Bowl",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Oatmeal Breakfast Bowl-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Breakfast",
            "src": "/assets/products/BV-MD-SNACK-Oatmeal Breakfast Bowl-RIGHT.png"
        },
        {
            "name": "Pancakes",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Pancakes-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Breakfast",
            "src": "/assets/products/BV-MD-SNACK-Pancakes-RIGHT.png"
        },
        {
            "name": "Panini",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Panini-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Sandwich",
            "src": "/assets/products/BV-MD-SNACK-Panini-RIGHT.png"
        },
        {
            "name": "Pasta",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Pasta-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Savoury",
            "src": "/assets/products/BV-MD-SNACK-Pasta-RIGHT.png"
        },
        {
            "name": "Pasta Bowl",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Pasta Bowl-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Main",
            "src": "/assets/products/BV-MD-SNACK-Pasta Bowl-RIGHT.png"
        },
        {
            "name": "Pasta Feta Cheese Bowl",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Pasta Feta Cheese Bowl-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Salad",
            "src": "/assets/products/BV-MD-SNACK-Pasta Feta Cheese Bowl-RIGHT.png"
        },
        {
            "name": "Pasty",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Pasty-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Savoury",
            "src": "/assets/products/BV-MD-SNACK-Pasty-RIGHT.png"
        },
        {
            "name": "Pink Grapes",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Pink Grapes-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Fruit/Nuts",
            "src": "/assets/products/BV-MD-SNACK-Pink Grapes-RIGHT.png"
        },
        {
            "name": "Pipers Anglesey Sea Salt Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Pipers Anglesey Sea Salt Crisps-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Pipers Anglesey Sea Salt Crisps-RIGHT.png"
        },
        {
            "name": "Pipers Anglesey Wild Rosemary & Thyme Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Pipers Anglesey Wild Rosemary & Thyme Crisps-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Pipers Anglesey Wild Rosemary & Thyme Crisps-RIGHT.png"
        },
        {
            "name": "Plain Pasta Bowl",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Plain Pasta Bowl-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Savoury",
            "src": "/assets/products/BV-MD-SNACK-Plain Pasta Bowl-RIGHT.png"
        },
        {
            "name": "Popchips Barbeque Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Pop Chips Barbeque Crisps-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Pop Chips Barbeque Crisps-RIGHT.png"
        },
        {
            "name": "Popchips BBQ Potato Snacks",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Popchips BBQ Potato Snacks-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Popchips BBQ Potato Snacks-RIGHT.png"
        },
        {
            "name": "Popchips Hot Spicy Potato Snacks",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Popchips Hot Spicy Potato Snacks-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Popchips Hot Spicy Potato Snacks-RIGHT.png"
        },
        {
            "name": "Popchips Sea Salt Potato Snacks",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Popchips Sea Salt-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Popchips Sea Salt-RIGHT.png"
        },
        {
            "name": "Popchips Sour Cream & Onion",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Popchips Sour Cream-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Popchips Sour Cream-RIGHT.png"
        },
        {
            "name": "Popchips Sour Cream & Onion Potato Snacks",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Popchips Sour Cream-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Popchips Sour Cream-RIGHT.png"
        },
        {
            "name": "Pringles Original 165g",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Pringles Original 165g-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Pringles Original 165g-RIGHT.png"
        },
        {
            "name": "Pringles Original 40g",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Pringles Original 40g-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Pringles Original 40g-RIGHT.png"
        },
        {
            "name": "Pringles Salt and Vinegar 40g",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Pringles Salt and Vinegar 40g-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Pringles Salt and Vinegar 40g-RIGHT.png"
        },
        {
            "name": "Pringles Sour Cream & Onion 165g",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Pringles Sour Cream & Onion 165g-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Pringles Sour Cream & Onion 165g-RIGHT.png"
        },
        {
            "name": "Pringles Sour Cream and Onion 40g",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Pringles Sour Cream and Onion 40g-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Pringles Sour Cream and Onion 40g-RIGHT.png"
        },
        {
            "name": "Pringles Texas BBQ Sauce 165g",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Pringles Texas BBQ Sauce 165g-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Pringles Texas BBQ Sauce 165g-RIGHT.png"
        },
        {
            "name": "Proper Corn Salted Caramel Popcorn",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Proper Corn Salted Caramel Popcorn-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Proper Corn Salted Caramel Popcorn-RIGHT.png"
        },
        {
            "name": "Proper Corn Sea Salted Popcorn",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Proper Corn Sea Salted Popcorn-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Proper Corn Sea Salted Popcorn-RIGHT.png"
        },
        {
            "name": "Proper Corn Sweet and Salty Popcorn",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Proper Corn Sweet and Salty Popcorn-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Proper Corn Sweet and Salty Popcorn-RIGHT.png"
        },
        {
            "name": "Prosciutto and Egg Salad Bowl",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Prosciutto and Egg Salad Bowl-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Salad",
            "src": "/assets/products/BV-MD-SNACK-Prosciutto and Egg Salad Bowl-RIGHT.png"
        },
        {
            "name": "Pumpkin Soup",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Pumpkin Soup-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Soup",
            "src": "/assets/products/BV-MD-SNACK-Pumpkin Soup-RIGHT.png"
        },
        {
            "name": "Rasberries",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Rasberries-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Fruit/Nuts",
            "src": "/assets/products/BV-MD-SNACK-Rasberries-RIGHT.png"
        },
        {
            "name": "Real Jalapeno Pepper Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Real Jalapeno Pepper Crisps-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Real Jalapeno Pepper Crisps-RIGHT.png"
        },
        {
            "name": "Real Roast Ox Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Real Roast Ox Crisps-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Real Roast Ox Crisps-RIGHT.png"
        },
        {
            "name": "Real Sea Salt & Cider Vinegar Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Real Sea Salt & Cider Vinegar Crisps-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Real Sea Salt & Cider Vinegar Crisps-RIGHT.png"
        },
        {
            "name": "Real Sea Salt Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Real Sea Salt Crisps-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Real Sea Salt Crisps-RIGHT.png"
        },
        {
            "name": "Real Strong Cheese & Onion Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Real Strong Cheese & Onion Crisps-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Real Strong Cheese & Onion Crisps-RIGHT.png"
        },
        {
            "name": "Real Sweet Chilli Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Real Sweet Chilli Crisps-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Real Sweet Chilli Crisps-RIGHT.png"
        },
        {
            "name": "Red apple",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Red apple-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Fruit/Nuts",
            "src": "/assets/products/BV-MD-SNACK-Red apple-RIGHT.png"
        },
        {
            "name": "Red Grapes",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Red Grapes-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Fruit/Nuts",
            "src": "/assets/products/BV-MD-SNACK-Red Grapes-RIGHT.png"
        },
        {
            "name": "Risotto",
            "shortDesc": "Main",
            "longDesc": "Mains",
            "imageUrl": "BV-MD-MAINS-Risotto - RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Savoury",
            "src": "/assets/products/BV-MD-MAINS-Risotto - RIGHT.png"
        },
        {
            "name": "Rowntrees Jelly Tots",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Rowntrees Jelly Tots-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Sweets",
            "src": "/assets/products/BV-MD-SNACK-Rowntrees Jelly Tots-RIGHT.png"
        },
        {
            "name": "Salmon Salad Bowl",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Salmon Salad Bowl-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Salad",
            "src": "/assets/products/BV-MD-SNACK-Salmon Salad Bowl-RIGHT.png"
        },
        {
            "name": "Salt and Vinegar Hula Hoops",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Salt and Vinegar Hula Hoops-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Salt and Vinegar Hula Hoops-RIGHT.png"
        },
        {
            "name": "Sandwich 1",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Sandwich 1-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Sandwich",
            "src": "/assets/products/BV-MD-SNACK-Sandwich 1-RIGHT.png"
        },
        {
            "name": "Sandwich 2",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Sandwich 2-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Sandwich",
            "src": "/assets/products/BV-MD-SNACK-Sandwich 2-RIGHT.png"
        },
        {
            "name": "Sandwich 3",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Sandwich 3-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Sandwich",
            "src": "/assets/products/BV-MD-SNACK-Sandwich 3-RIGHT.png"
        },
        {
            "name": "Sandwich 4",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Sandwich 4-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Sandwich",
            "src": "/assets/products/BV-MD-SNACK-Sandwich 4-RIGHT.png"
        },
        {
            "name": "Sausage Breakfast Bap",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Sausage Breakfast Bap-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Breakfast",
            "src": "/assets/products/BV-MD-SNACK-Sausage Breakfast Bap-RIGHT.png"
        },
        {
            "name": "Seabrooks Beefy Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Seabrooks Beefy Crisps-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Seabrooks Beefy Crisps-RIGHT.png"
        },
        {
            "name": "Seabrooks Cheese & Onion Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Seabrooks Cheese & Onion Crisps-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Seabrooks Cheese & Onion Crisps-RIGHT.png"
        },
        {
            "name": "Seabrooks Prawn Cocktail Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Seabrooks Prawn Cocktail Crisps-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Seabrooks Prawn Cocktail Crisps-RIGHT.png"
        },
        {
            "name": "Seabrooks Sea Salt & Vinegar Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Seabrooks Sea Salt & Vinegar Crisps-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Seabrooks Sea Salt & Vinegar Crisps-RIGHT.png"
        },
        {
            "name": "Seabrooks Sea Salted Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Seabrooks Sea Salted Crisps-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Seabrooks Sea Salted Crisps-RIGHT.png"
        },
        {
            "name": "Skips Prawn Cocktail Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Skips Prawn Cocktail Crisps-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Skips Prawn Cocktail Crisps-RIGHT.png"
        },
        {
            "name": "Skittles",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Skittles-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Sweets",
            "src": "/assets/products/BV-MD-SNACK-Skittles-RIGHT.png"
        },
        {
            "name": "Smiths Frazzles Crispy Bacon",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Smiths Frazzles Crispy Bacon-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Smiths Frazzles Crispy Bacon-RIGHT.png"
        },
        {
            "name": "Smiths Scampi Lemon Snacks",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Smiths Scampi Lemon Snacks-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Smiths Scampi Lemon Snacks-RIGHT.png"
        },
        {
            "name": "Snickers",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Snickers-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Snickers-RIGHT.png"
        },
        {
            "name": "Sponge cake",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Sponge cake-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Cake",
            "src": "/assets/products/BV-MD-SNACK-Sponge cake-RIGHT.png"
        },
        {
            "name": "Starburst",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Starburst-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Sweets",
            "src": "/assets/products/BV-MD-SNACK-Starburst-RIGHT.png"
        },
        {
            "name": "Steak Pie",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Steak Pie 2-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Savoury",
            "src": "/assets/products/BV-MD-SNACK-Steak Pie 2-RIGHT.png"
        },
        {
            "name": "Strawberries",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Strawberries-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Fruit/Nuts",
            "src": "/assets/products/BV-MD-SNACK-Strawberries-RIGHT.png"
        },
        {
            "name": "Swizzels Drumsticks Squashies",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Swizzels Drumsticks Squashies-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Sweets",
            "src": "/assets/products/BV-MD-SNACK-Swizzels Drumsticks Squashies-RIGHT.png"
        },
        {
            "name": "Takeaway (small)",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-TakeawaySM-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Hot Drinks",
            "src": "/assets/products/BV-MD-SNACK-TakeawaySM-RIGHT.png"
        },
        {
            "name": "Terry's Chocolate Orange",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Terry's Chocolate Orange-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Terry's Chocolate Orange-RIGHT.png"
        },
        {
            "name": "Tikka Masala",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Tikka Masala-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Curry",
            "src": "/assets/products/BV-MD-SNACK-Tikka Masala-RIGHT.png"
        },
        {
            "name": "Toffee Crisp",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Toffee Crisp-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Toffee Crisp-RIGHT.png"
        },
        {
            "name": "Tofu Veggie Bowl",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Tofu Veggie Bowl-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Salad",
            "src": "/assets/products/BV-MD-SNACK-Tofu Veggie Bowl-RIGHT.png"
        },
        {
            "name": "Tuna Sandwich",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Tuna Sandwich-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Sandwich",
            "src": "/assets/products/BV-MD-SNACK-Tuna Sandwich-RIGHT.png"
        },
        {
            "name": "Twix",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Twix-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Twix-RIGHT.png"
        },
        {
            "name": "Tyrells Lightly Salted Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Tyrells Lightly Salted Crisps-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Tyrells Lightly Salted Crisps-RIGHT.png"
        },
        {
            "name": "Tyrells Mature Cheddar Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Tyrells Mature Cheddar Crisps-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Tyrells Mature Cheddar Crisps-RIGHT.png"
        },
        {
            "name": "Tyrells Veg Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Tyrells Veg Crisps-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Tyrells Veg Crisps-RIGHT.png"
        },
        {
            "name": "Vegan Buddha Bowl",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Vegan Buddha Bowl-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Salad",
            "src": "/assets/products/BV-MD-SNACK-Vegan Buddha Bowl-RIGHT.png"
        },
        {
            "name": "Waffles",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Waffles-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Breakfast",
            "src": "/assets/products/BV-MD-SNACK-Waffles-RIGHT.png"
        },
        {
            "name": "Walkers Baked Cheese & Onion Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Walkers Baked Cheese & Onion Crisps-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Walkers Baked Cheese & Onion Crisps-RIGHT.png"
        },
        {
            "name": "Walkers Baked Salt & Vinegar Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Walkers Baked Salt & Vinegar Crisps-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Walkers Baked Salt & Vinegar Crisps-RIGHT.png"
        },
        {
            "name": "Walkers Baked Sea Salt Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Walkers Baked Sea Salt Crisps-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Walkers Baked Sea Salt Crisps-RIGHT.png"
        },
        {
            "name": "Walkers Beef Monster Munch",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Walkers Beef Monster Munch-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Walkers Beef Monster Munch-RIGHT.png"
        },
        {
            "name": "Walkers Cheese & Onion Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-WalkersCheeseOnion-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-WalkersCheeseOnion-RIGHT.png"
        },
        {
            "name": "Walkers Cheese and Onion Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Walkers Cheese and Onion Crisps-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Walkers Cheese and Onion Crisps-RIGHT.png"
        },
        {
            "name": "Walkers Pickled Onion Monster Munch",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Walkers Pickled Onion Monster Munch-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Walkers Pickled Onion Monster Munch-RIGHT.png"
        },
        {
            "name": "Walkers Prawn Cocktail Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Walkers Prawn Cocktail Crisps-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Walkers Prawn Cocktail Crisps-RIGHT.png"
        },
        {
            "name": "Walkers Quavers Cheese",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Walkers Quavers Cheese-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Walkers Quavers Cheese-RIGHT.png"
        },
        {
            "name": "Walkers Ready Salted Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Walkers Ready Salted Crisps-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Walkers Ready Salted Crisps-RIGHT.png"
        },
        {
            "name": "Walkers Salt & Vinegar Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-WalkersSaltVinegar-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-WalkersSaltVinegar-RIGHT.png"
        },
        {
            "name": "Walkers Salt and Vinegar Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Walkers Salt and Vinegar Crisps-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Walkers Salt and Vinegar Crisps-RIGHT.png"
        },
        {
            "name": "Walkers Salt and Vinegar Squares",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Walkers Salt and Vinegar Squares-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Walkers Salt and Vinegar Squares-RIGHT.png"
        },
        {
            "name": "Walkers Wotsits Really Cheesey",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Walkers Wotsits Really Cheesey-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Walkers Wotsits Really Cheesey-RIGHT.png"
        },
        {
            "name": "Werthers Original",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Werthers Original-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Sweets",
            "src": "/assets/products/BV-MD-SNACK-Werthers Original-RIGHT.png"
        },
        {
            "name": "White Grapes",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-White Grapes-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Fruit/Nuts",
            "src": "/assets/products/BV-MD-SNACK-White Grapes-RIGHT.png"
        },
        {
            "name": "Wide bowl fries",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Wide bowl fries-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Fries",
            "src": "/assets/products/BV-MD-SNACK-Wide bowl fries-RIGHT.png"
        },
        {
            "name": "Wrapped Sandwich",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Wrapped Sandwich-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Sandwich",
            "src": "/assets/products/BV-MD-SNACK-Wrapped Sandwich-RIGHT.png"
        },
        {
            "name": "Yoghurt",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Yoghurt-RIGHT.png",
            "backgroundColour": "#fff",
            "category": "Breakfast",
            "src": "/assets/products/BV-MD-SNACK-Yoghurt-RIGHT.png"
        },
        {
            "name": "Yogurt Breakfast Bowl",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Yogurt Breakfast Bowl-RIGHT.png",
            "backgroundColour": "#ffffff",
            "category": "Breakfast",
            "src": "/assets/products/BV-MD-SNACK-Yogurt Breakfast Bowl-RIGHT.png"
        },
        {
            "name": "2 Southern Fried Chicken",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-2 Southern Fried Chicken-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Savoury",
            "src": "/assets/products/BV-MD-SNACK-2 Southern Fried Chicken-LEFT.png"
        },
        {
            "name": "3 Southern Fried Chicken",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-3 Southern Fried Chicken-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Savoury",
            "src": "/assets/products/BV-MD-SNACK-3 Southern Fried Chicken-LEFT.png"
        },
        {
            "name": "Americano",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Americano-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Hot Drinks",
            "src": "/assets/products/BV-MD-SNACK-Americano-LEFT.png"
        },
        {
            "name": "Bacon Breakfast Bap",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Bacon Breakfast Bap-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Breakfast",
            "src": "/assets/products/BV-MD-SNACK-Bacon Breakfast Bap-LEFT.png"
        },
        {
            "name": "Bacon Egg Sausage Breakfast Bap",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Bacon Egg Sausage Breakfast Bap-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Breakfast",
            "src": "/assets/products/BV-MD-SNACK-Bacon Egg Sausage Breakfast Bap-LEFT.png"
        },
        {
            "name": "Baguette",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Baguette-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Sandwich",
            "src": "/assets/products/BV-MD-SNACK-Baguette-LEFT.png"
        },
        {
            "name": "Banana",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Banana-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Fruit/Nuts",
            "src": "/assets/products/BV-MD-SNACK-Banana-LEFT.png"
        },
        {
            "name": "Barratt Refresher Softies",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Barratt Refresher Softies-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Sweets",
            "src": "/assets/products/BV-MD-SNACK-Barratt Refresher Softies-LEFT.png"
        },
        {
            "name": "Beef Hula Hoops",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Beef Hula Hoops-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Beef Hula Hoops-LEFT.png"
        },
        {
            "name": "Boiled Egg",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Boiled egg-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Savoury",
            "src": "/assets/products/BV-MD-SNACK-Boiled egg-LEFT.png"
        },
        {
            "name": "Bowl of Cereal",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Bowl of Cereal 2-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Breakfast",
            "src": "/assets/products/BV-MD-SNACK-Bowl of Cereal 2-LEFT.png"
        },
        {
            "name": "Bowl of Wedges",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Bowl Wedges-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Savoury",
            "src": "/assets/products/BV-MD-SNACK-Bowl Wedges-LEFT.png"
        },
        {
            "name": "Breakfast Bowl",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Breakfast Bowl-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Breakfast",
            "src": "/assets/products/BV-MD-SNACK-Breakfast Bowl-LEFT.png"
        },
        {
            "name": "Buchanans Chocolate Caramels",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Buchanans Chocolate Caramels-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Buchanans Chocolate Caramels-LEFT.png"
        },
        {
            "name": "Burger",
            "shortDesc": "Mains",
            "longDesc": "Mains",
            "imageUrl": "BV-MD-SNACK-Burger-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Burger",
            "src": "/assets/products/BV-MD-SNACK-Burger-LEFT.png"
        },
        {
            "name": "Burts Lightly Sea Salted Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Burts Lightly Sea Salted-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Burts Lightly Sea Salted-LEFT.png"
        },
        {
            "name": "Burts Mature Cheddar & Onion Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Burts Mature Cheddar & Onion-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Burts Mature Cheddar & Onion-LEFT.png"
        },
        {
            "name": "Burts Sea Salt & Malt Vinegar Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Burts Sea Salt & Malt Vinegar-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Burts Sea Salt & Malt Vinegar-LEFT.png"
        },
        {
            "name": "Cadbury Dairy Milk",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Cadbury Dairy Milk-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Cadbury Dairy Milk-LEFT.png"
        },
        {
            "name": "Cadbury Dairy Milk Caramel",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Cadbury Dairy Milk Caramel-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Cadbury Dairy Milk Caramel-LEFT.png"
        },
        {
            "name": "Cadbury Dairy Milk Marvellous Creations",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Cadbury Dairy Milk Marvellous Creations-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Cadbury Dairy Milk Marvellous Creations-LEFT.png"
        },
        {
            "name": "Cadbury Double Decker",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Cadbury Double Decker-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Cadbury Double Decker-LEFT.png"
        },
        {
            "name": "Cadbury Flake",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Cadbury Flake-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Cadbury Flake-LEFT.png"
        },
        {
            "name": "Cadbury Twirl",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Cadbury Twirl-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Cadbury Twirl-LEFT.png"
        },
        {
            "name": "Cadbury Wispa",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Cadbury Wispa-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Cadbury Wispa-LEFT.png"
        },
        {
            "name": "Carrot Cream Soup",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Carrot Cream Soup-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Soup",
            "src": "/assets/products/BV-MD-SNACK-Carrot Cream Soup-LEFT.png"
        },
        {
            "name": "Carrot Sticks (Left)",
            "shortDesc": "Snack",
            "longDesc": "Snack",
            "imageUrl": "BV-MD-MAINS-CarrotSticks-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Fruit/Nuts",
            "src": "/assets/products/BV-MD-MAINS-CarrotSticks-LEFT.png"
        },
        {
            "name": "Cereal Bar",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Cereal Bar-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Cake",
            "src": "/assets/products/BV-MD-SNACK-Cereal Bar-LEFT.png"
        },
        {
            "name": "Cheese Sandwich",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Cheese Sandwich-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Sandwich",
            "src": "/assets/products/BV-MD-SNACK-Cheese Sandwich-LEFT.png"
        },
        {
            "name": "Chewy Sweets",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Chewy Sweets-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Sweets",
            "src": "/assets/products/BV-MD-SNACK-Chewy Sweets-LEFT.png"
        },
        {
            "name": "Chicken Burger",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Chicken Burger-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Burger",
            "src": "/assets/products/BV-MD-SNACK-Chicken Burger-LEFT.png"
        },
        {
            "name": "Chicken Caesar Salad",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Chicken Caesar Salad-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Salad",
            "src": "/assets/products/BV-MD-SNACK-Chicken Caesar Salad-LEFT.png"
        },
        {
            "name": "Chicken Katsu Curry",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Chicken Katsu Curry-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Curry",
            "src": "/assets/products/BV-MD-SNACK-Chicken Katsu Curry-LEFT.png"
        },
        {
            "name": "Chicken Mayo Sandwich",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Chicken Mayo Sandwich-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Sandwich",
            "src": "/assets/products/BV-MD-SNACK-Chicken Mayo Sandwich-LEFT.png"
        },
        {
            "name": "Chicken Noodle Soup",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Chicken Noodle Soup-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Soup",
            "src": "/assets/products/BV-MD-SNACK-Chicken Noodle Soup-LEFT.png"
        },
        {
            "name": "Chicken Wrap 1",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Chicken Wrap 1-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Main",
            "src": "/assets/products/BV-MD-SNACK-Chicken Wrap 1-LEFT.png"
        },
        {
            "name": "Chicken Wrap 2",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Chicken Wrap 2-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Main",
            "src": "/assets/products/BV-MD-SNACK-Chicken Wrap 2-LEFT.png"
        },
        {
            "name": "Chocolate Cake",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Chocolate Cake-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Cake",
            "src": "/assets/products/BV-MD-SNACK-Chocolate Cake-LEFT.png"
        },
        {
            "name": "Chocolate Cookie",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Chocolate Cookie-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Cookie",
            "src": "/assets/products/BV-MD-SNACK-Chocolate Cookie-LEFT.png"
        },
        {
            "name": "Clementine",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Clementine-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Fruit/Nuts",
            "src": "/assets/products/BV-MD-SNACK-Clementine-LEFT.png"
        },
        {
            "name": "Coleslaw",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Coleslaw-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Savoury",
            "src": "/assets/products/BV-MD-SNACK-Coleslaw-LEFT.png"
        },
        {
            "name": "Couscous",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Couscous-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Salad",
            "src": "/assets/products/BV-MD-SNACK-Couscous-LEFT.png"
        },
        {
            "name": "Crackers",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Crackers-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Cookie",
            "src": "/assets/products/BV-MD-SNACK-Crackers-LEFT.png"
        },
        {
            "name": "Crunchie",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Crunchie-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Crunchie-LEFT.png"
        },
        {
            "name": "Doritos Cool Original",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Doritos Cool Original-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Doritos Cool Original-LEFT.png"
        },
        {
            "name": "Doritos Tangy Cheese",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Doritos Tangy Cheese-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Doritos Tangy Cheese-LEFT.png"
        },
        {
            "name": "Eat Real Tomato and Basil Lentil Chips",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Eat_Real_Lentil-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Eat_Real_Lentil-LEFT.png"
        },
        {
            "name": "Egg Breakfast Bap",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Egg Breakfast Bap-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Breakfast",
            "src": "/assets/products/BV-MD-SNACK-Egg Breakfast Bap-LEFT.png"
        },
        {
            "name": "Egg Mayo Sandwich",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Egg Mayo Sandwich-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Sandwich",
            "src": "/assets/products/BV-MD-SNACK-Egg Mayo Sandwich-LEFT.png"
        },
        {
            "name": "Falafel",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Falafel-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Salad",
            "src": "/assets/products/BV-MD-SNACK-Falafel-LEFT.png"
        },
        {
            "name": "Feta Salad Bowl",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Feta Salad Bowl-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Salad",
            "src": "/assets/products/BV-MD-SNACK-Feta Salad Bowl-LEFT.png"
        },
        {
            "name": "Fruit Sweets",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Fruit Sweets-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Sweets",
            "src": "/assets/products/BV-MD-SNACK-Fruit Sweets-LEFT.png"
        },
        {
            "name": "Fruit-Tella",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Fruit-Tella-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Sweets",
            "src": "/assets/products/BV-MD-SNACK-Fruit-Tella-LEFT.png"
        },
        {
            "name": "Frys Chocolate Cream",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Frys Chocolate Cream-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Frys Chocolate Cream-LEFT.png"
        },
        {
            "name": "Frys Peppermint Cream",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Frys Peppermint Cream-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Frys Peppermint Cream-LEFT.png"
        },
        {
            "name": "Frys Turkish Delight",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Frys Turkish Delight-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Frys Turkish Delight-LEFT.png"
        },
        {
            "name": "Galaxy Ripple",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Galaxy Ripple-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Galaxy Ripple-LEFT.png"
        },
        {
            "name": "Galaxy Smooth Milk",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Galaxy Smooth Milk-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Galaxy Smooth Milk-LEFT.png"
        },
        {
            "name": "Garlic Bread",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Garlic Bread-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Savoury",
            "src": "/assets/products/BV-MD-SNACK-Garlic Bread-LEFT.png"
        },
        {
            "name": "Green apple",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Green apple-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Fruit/Nuts",
            "src": "/assets/products/BV-MD-SNACK-Green apple-LEFT.png"
        },
        {
            "name": "Green Avocado Salad",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Green Avocado Salad-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Salad",
            "src": "/assets/products/BV-MD-SNACK-Green Avocado Salad-LEFT.png"
        },
        {
            "name": "Grilled Sandwich",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Grilled Sandwich-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Sandwich",
            "src": "/assets/products/BV-MD-SNACK-Grilled Sandwich-LEFT.png"
        },
        {
            "name": "Ham Roll",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Ham Roll-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Sandwich",
            "src": "/assets/products/BV-MD-SNACK-Ham Roll-LEFT.png"
        },
        {
            "name": "Ham Sandwich",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Ham Sandwich-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Sandwich",
            "src": "/assets/products/BV-MD-SNACK-Ham Sandwich-LEFT.png"
        },
        {
            "name": "Ham, Cheese & Tomato Sandwich",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Ham, Cheese & Tomato Sandwich-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Sandwich",
            "src": "/assets/products/BV-MD-SNACK-Ham, Cheese & Tomato Sandwich-LEFT.png"
        },
        {
            "name": "Ham, Cheese & Tomato Thick  Sandwich",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Ham, Cheese & Tomato Thick  Sandwich-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Sandwich",
            "src": "/assets/products/BV-MD-SNACK-Ham, Cheese & Tomato Thick  Sandwich-LEFT.png"
        },
        {
            "name": "Haribo Starmix",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Haribo Starmix-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Sweets",
            "src": "/assets/products/BV-MD-SNACK-Haribo Starmix-LEFT.png"
        },
        {
            "name": "Haribo Tanfastics",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Haribo Tanfastics-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Sweets",
            "src": "/assets/products/BV-MD-SNACK-Haribo Tanfastics-LEFT.png"
        },
        {
            "name": "Houmous",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Houmous-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Salad",
            "src": "/assets/products/BV-MD-SNACK-Houmous-LEFT.png"
        },
        {
            "name": "Jacket Potato",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Jacket Potato-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Main",
            "src": "/assets/products/BV-MD-SNACK-Jacket Potato-LEFT.png"
        },
        {
            "name": "Jacket Potato with beans",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Jacket Potato with beans-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Savoury",
            "src": "/assets/products/BV-MD-SNACK-Jacket Potato with beans-LEFT.png"
        },
        {
            "name": "Jacket Potato with cheese",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Jacket Potato with cheese-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Savoury",
            "src": "/assets/products/BV-MD-SNACK-Jacket Potato with cheese-LEFT.png"
        },
        {
            "name": "Jacket Potato with tuna",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Jacket Potato with tuna-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Savoury",
            "src": "/assets/products/BV-MD-SNACK-Jacket Potato with tuna-LEFT.png"
        },
        {
            "name": "Kent Crisps Cheese and Onion",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Kent Crisps Cheese and Onion-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Kent Crisps Cheese and Onion-LEFT.png"
        },
        {
            "name": "Kent Crisps Salt and Vinegar",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Kent Crisps Salt and Vinegar-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Kent Crisps Salt and Vinegar-LEFT.png"
        },
        {
            "name": "Kettle Lightly Salted Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Kettle Lightly Salted Crisps-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Kettle Lightly Salted Crisps-LEFT.png"
        },
        {
            "name": "Kettle Sea Salt & Balsamic Vinegar Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Kettle Sea Salt & Balsamic Vinegar Crisps-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Kettle Sea Salt & Balsamic Vinegar Crisps-LEFT.png"
        },
        {
            "name": "Kit Kat",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Kit Kat-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Kit Kat-LEFT.png"
        },
        {
            "name": "Kit Kat Chunky",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Kit Kat Chunky-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Kit Kat Chunky-LEFT.png"
        },
        {
            "name": "Latte",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Latte-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Hot Drinks",
            "src": "/assets/products/BV-MD-SNACK-Latte-LEFT.png"
        },
        {
            "name": "Lion Bar",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Lion Bar-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Lion Bar-LEFT.png"
        },
        {
            "name": "Listers Cheese & Onion Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Listers Cheese & Onion Crisps-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Listers Cheese & Onion Crisps-LEFT.png"
        },
        {
            "name": "Listers Sea Salt & Vinegar Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Listers Sea Salt & Vinegar Crisps-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Listers Sea Salt & Vinegar Crisps-LEFT.png"
        },
        {
            "name": "Listers Sea Salt Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Listers Sea Salt Crisps-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Listers Sea Salt Crisps-LEFT.png"
        },
        {
            "name": "Listers Sea Steak & Ale Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Listers Sea Steak & Ale Crisps-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Listers Sea Steak & Ale Crisps-LEFT.png"
        },
        {
            "name": "Listers Sweet Chilli Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Listers Sweet Chilli Crisps-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Listers Sweet Chilli Crisps-LEFT.png"
        },
        {
            "name": "Mars Bar (Left)",
            "shortDesc": "Snack",
            "longDesc": "Snack",
            "imageUrl": "BV-MD-SNACK-MarsBar-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-MarsBar-LEFT.png"
        },
        {
            "name": "Mars Maltesers",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Mars Maltesers-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Mars Maltesers-LEFT.png"
        },
        {
            "name": "Maynards Bassetts Jelly Babies",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Maynards Bassetts Jelly Babies-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Sweets",
            "src": "/assets/products/BV-MD-SNACK-Maynards Bassetts Jelly Babies-LEFT.png"
        },
        {
            "name": "McCoys Ridge Flamed Grill Steak Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-McCoys Ridge Flamed Grill Steak Crisps-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-McCoys Ridge Flamed Grill Steak Crisps-LEFT.png"
        },
        {
            "name": "McCoys Ridge Salted Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-McCoys Ridge Salted Crisps-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-McCoys Ridge Salted Crisps-LEFT.png"
        },
        {
            "name": "Meat Feast Pizza",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Meat Feast Pizza-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Pizza",
            "src": "/assets/products/BV-MD-SNACK-Meat Feast Pizza-LEFT.png"
        },
        {
            "name": "Meatballs",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Meatballs-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Main",
            "src": "/assets/products/BV-MD-SNACK-Meatballs-LEFT.png"
        },
        {
            "name": "Medium bowl fries",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Medium bowl fries-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Fries",
            "src": "/assets/products/BV-MD-SNACK-Medium bowl fries-LEFT.png"
        },
        {
            "name": "Milky Bar",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Milky Bar-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Milky Bar-LEFT.png"
        },
        {
            "name": "Muffin",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Muffin-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Cake",
            "src": "/assets/products/BV-MD-SNACK-Muffin-LEFT.png"
        },
        {
            "name": "Nachos Bowl",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Nachos Bowl -LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Savoury",
            "src": "/assets/products/BV-MD-SNACK-Nachos Bowl -LEFT.png"
        },
        {
            "name": "Nachos Plate 1",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Nachos Plate 1-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Savoury",
            "src": "/assets/products/BV-MD-SNACK-Nachos Plate 1-LEFT.png"
        },
        {
            "name": "Nachos Plate 2",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Nachos Plate 2-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Savoury",
            "src": "/assets/products/BV-MD-SNACK-Nachos Plate 2-LEFT.png"
        },
        {
            "name": "Nestle Aero",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Nestle Aero-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Nestle Aero-LEFT.png"
        },
        {
            "name": "Nestle Smarties",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Nestle Smarties-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Nestle Smarties-LEFT.png"
        },
        {
            "name": "Nestle Yorkie",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Nestle Yorkie-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Nestle Yorkie-LEFT.png"
        },
        {
            "name": "Nik Naks Nice ‘n’ Spicy",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Nik Naks NiceN Spicy-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Nik Naks NiceN Spicy-LEFT.png"
        },
        {
            "name": "Noodle Bowl",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Noodle bowl-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Main",
            "src": "/assets/products/BV-MD-SNACK-Noodle bowl-LEFT.png"
        },
        {
            "name": "Nuts",
            "shortDesc": "Snack",
            "longDesc": "Snack",
            "imageUrl": "BV-MD-SNACK-Nuts-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Fruit/Nuts",
            "src": "/assets/products/BV-MD-SNACK-Nuts-LEFT.png"
        },
        {
            "name": "Oatmeal Breakfast Bowl",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Oatmeal Breakfast Bowl-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Breakfast",
            "src": "/assets/products/BV-MD-SNACK-Oatmeal Breakfast Bowl-LEFT.png"
        },
        {
            "name": "Pancakes",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Pancakes-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Breakfast",
            "src": "/assets/products/BV-MD-SNACK-Pancakes-LEFT.png"
        },
        {
            "name": "Panini",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Panini-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Sandwich",
            "src": "/assets/products/BV-MD-SNACK-Panini-LEFT.png"
        },
        {
            "name": "Pasta",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Pasta-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Savoury",
            "src": "/assets/products/BV-MD-SNACK-Pasta-LEFT.png"
        },
        {
            "name": "Pasta Bowl",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Pasta Bowl-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Main",
            "src": "/assets/products/BV-MD-SNACK-Pasta Bowl-LEFT.png"
        },
        {
            "name": "Pasta Feta Cheese Bowl",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Pasta Feta Cheese Bowl-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Salad",
            "src": "/assets/products/BV-MD-SNACK-Pasta Feta Cheese Bowl-LEFT.png"
        },
        {
            "name": "Pasty",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Pasty-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Savoury",
            "src": "/assets/products/BV-MD-SNACK-Pasty-LEFT.png"
        },
        {
            "name": "Pineapple Pizza",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Pineapple Pizza-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Pizza",
            "src": "/assets/products/BV-MD-SNACK-Pineapple Pizza-LEFT.png"
        },
        {
            "name": "Pink Grapes",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Pink Grapes-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Fruit/Nuts",
            "src": "/assets/products/BV-MD-SNACK-Pink Grapes-LEFT.png"
        },
        {
            "name": "Pipers Anglesey Sea Salt Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Pipers Anglesey Sea Salt Crisps-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Pipers Anglesey Sea Salt Crisps-LEFT.png"
        },
        {
            "name": "Pipers Anglesey Wild Rosemary & Thyme Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Pipers Anglesey Wild Rosemary & Thyme Crisps-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Pipers Anglesey Wild Rosemary & Thyme Crisps-LEFT.png"
        },
        {
            "name": "Plain Pasta Bowl",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Plain Pasta Bowl-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Savoury",
            "src": "/assets/products/BV-MD-SNACK-Plain Pasta Bowl-LEFT.png"
        },
        {
            "name": "Popchips Barbeque Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Pop Chips Barbeque Crisps-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Pop Chips Barbeque Crisps-LEFT.png"
        },
        {
            "name": "Popchips BBQ Potato Snacks",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Popchips BBQ Potato Snacks-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Popchips BBQ Potato Snacks-LEFT.png"
        },
        {
            "name": "Popchips Hot Spicy Potato Snacks",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Popchips Hot Spicy Potato Snacks-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Popchips Hot Spicy Potato Snacks-LEFT.png"
        },
        {
            "name": "Popchips Sea Salt Potato Snacks",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Popchips Sea Salt-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Popchips Sea Salt-LEFT.png"
        },
        {
            "name": "Popchips Sour Cream & Onion",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Popchips Sour Cream-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Popchips Sour Cream-LEFT.png"
        },
        {
            "name": "Popchips Sour Cream & Onion Potato Snacks",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Popchips Sour Cream-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Popchips Sour Cream-LEFT.png"
        },
        {
            "name": "Pringles Original 165g",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Pringles Original 165g-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Pringles Original 165g-LEFT.png"
        },
        {
            "name": "Pringles Original 40g",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Pringles Original 40g-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Pringles Original 40g-LEFT.png"
        },
        {
            "name": "Pringles Salt and Vinegar 40g",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Pringles Salt and Vinegar 40g-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Pringles Salt and Vinegar 40g-LEFT.png"
        },
        {
            "name": "Pringles Sour Cream & Onion 165g",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Pringles Sour Cream & Onion 165g-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Pringles Sour Cream & Onion 165g-LEFT.png"
        },
        {
            "name": "Pringles Sour Cream and Onion 40g",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Pringles Sour Cream and Onion 40g-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Pringles Sour Cream and Onion 40g-LEFT.png"
        },
        {
            "name": "Pringles Texas BBQ Sauce 165g",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Pringles Texas BBQ Sauce 165g-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Pringles Texas BBQ Sauce 165g-LEFT.png"
        },
        {
            "name": "Proper Corn Salted Caramel Popcorn",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Proper Corn Salted Caramel Popcorn-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Proper Corn Salted Caramel Popcorn-LEFT.png"
        },
        {
            "name": "Proper Corn Sea Salted Popcorn",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Proper Corn Sea Salted Popcorn-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Proper Corn Sea Salted Popcorn-LEFT.png"
        },
        {
            "name": "Proper Corn Sweet and Salty Popcorn",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Proper Corn Sweet and Salty Popcorn-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Proper Corn Sweet and Salty Popcorn-LEFT.png"
        },
        {
            "name": "Prosciutto and Egg Salad Bowl",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Prosciutto and Egg Salad Bowl-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Salad",
            "src": "/assets/products/BV-MD-SNACK-Prosciutto and Egg Salad Bowl-LEFT.png"
        },
        {
            "name": "Pumpkin Soup",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Pumpkin Soup-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Soup",
            "src": "/assets/products/BV-MD-SNACK-Pumpkin Soup-LEFT.png"
        },
        {
            "name": "Rasberries",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Rasberries-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Fruit/Nuts",
            "src": "/assets/products/BV-MD-SNACK-Rasberries-LEFT.png"
        },
        {
            "name": "Real Jalapeno Pepper Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Real Jalapeno Pepper Crisps-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Real Jalapeno Pepper Crisps-LEFT.png"
        },
        {
            "name": "Real Roast Ox Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Real Roast Ox Crisps-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Real Roast Ox Crisps-LEFT.png"
        },
        {
            "name": "Real Sea Salt & Cider Vinegar Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Real Sea Salt & Cider Vinegar Crisps-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Real Sea Salt & Cider Vinegar Crisps-LEFT.png"
        },
        {
            "name": "Real Sea Salt Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Real Sea Salt Crisps-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Real Sea Salt Crisps-LEFT.png"
        },
        {
            "name": "Real Strong Cheese & Onion Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Real Strong Cheese & Onion Crisps-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Real Strong Cheese & Onion Crisps-LEFT.png"
        },
        {
            "name": "Real Sweet Chilli Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Real Sweet Chilli Crisps-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Real Sweet Chilli Crisps-LEFT.png"
        },
        {
            "name": "Red apple",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Red apple-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Fruit/Nuts",
            "src": "/assets/products/BV-MD-SNACK-Red apple-LEFT.png"
        },
        {
            "name": "Red Grapes",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Red Grapes-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Fruit/Nuts",
            "src": "/assets/products/BV-MD-SNACK-Red Grapes-LEFT.png"
        },
        {
            "name": "Risotto (Left)",
            "shortDesc": "Main",
            "longDesc": "Mains",
            "imageUrl": "BV-MD-MAINS-Risotto-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Savoury",
            "src": "/assets/products/BV-MD-MAINS-Risotto-LEFT.png"
        },
        {
            "name": "Rowntrees Jelly Tots",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Rowntrees Jelly Tots-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Sweets",
            "src": "/assets/products/BV-MD-SNACK-Rowntrees Jelly Tots-LEFT.png"
        },
        {
            "name": "Salmon Salad Bowl",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Salmon Salad Bowl-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Salad",
            "src": "/assets/products/BV-MD-SNACK-Salmon Salad Bowl-LEFT.png"
        },
        {
            "name": "Salt and Vinegar Hula Hoops",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Salt and Vinegar Hula Hoops-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Salt and Vinegar Hula Hoops-LEFT.png"
        },
        {
            "name": "Sandwich 1",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Sandwich 1-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Sandwich",
            "src": "/assets/products/BV-MD-SNACK-Sandwich 1-LEFT.png"
        },
        {
            "name": "Sandwich 2",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Sandwich 2-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Sandwich",
            "src": "/assets/products/BV-MD-SNACK-Sandwich 2-LEFT.png"
        },
        {
            "name": "Sandwich 3",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Sandwich 3-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Sandwich",
            "src": "/assets/products/BV-MD-SNACK-Sandwich 3-LEFT.png"
        },
        {
            "name": "Sandwich 4",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Sandwich 4-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Sandwich",
            "src": "/assets/products/BV-MD-SNACK-Sandwich 4-LEFT.png"
        },
        {
            "name": "Sausage Breakfast Bap",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Sausage Breakfast Bap-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Breakfast",
            "src": "/assets/products/BV-MD-SNACK-Sausage Breakfast Bap-LEFT.png"
        },
        {
            "name": "Seabrooks Beefy Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Seabrooks Beefy Crisps-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Seabrooks Beefy Crisps-LEFT.png"
        },
        {
            "name": "Seabrooks Cheese & Onion Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Seabrooks Cheese & Onion Crisps-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Seabrooks Cheese & Onion Crisps-LEFT.png"
        },
        {
            "name": "Seabrooks Prawn Cocktail Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Seabrooks Prawn Cocktail Crisps-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Seabrooks Prawn Cocktail Crisps-LEFT.png"
        },
        {
            "name": "Seabrooks Sea Salt & Vinegar Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Seabrooks Sea Salt & Vinegar Crisps-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Seabrooks Sea Salt & Vinegar Crisps-LEFT.png"
        },
        {
            "name": "Seabrooks Sea Salted Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Seabrooks Sea Salted Crisps-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Seabrooks Sea Salted Crisps-LEFT.png"
        },
        {
            "name": "Skips Prawn Cocktail Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Skips Prawn Cocktail Crisps-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Skips Prawn Cocktail Crisps-LEFT.png"
        },
        {
            "name": "Skittles",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Skittles-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Sweets",
            "src": "/assets/products/BV-MD-SNACK-Skittles-LEFT.png"
        },
        {
            "name": "Smiths Frazzles Crispy Bacon",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Smiths Frazzles Crispy Bacon-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Smiths Frazzles Crispy Bacon-LEFT.png"
        },
        {
            "name": "Smiths Scampi Lemon Snacks",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Smiths Scampi Lemon Snacks-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Smiths Scampi Lemon Snacks-LEFT.png"
        },
        {
            "name": "Snickers",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Snickers-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Snickers-LEFT.png"
        },
        {
            "name": "Sponge cake",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Sponge cake-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Cake",
            "src": "/assets/products/BV-MD-SNACK-Sponge cake-LEFT.png"
        },
        {
            "name": "Starburst",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Starburst-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Sweets",
            "src": "/assets/products/BV-MD-SNACK-Starburst-LEFT.png"
        },
        {
            "name": "Steak Pie",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Steak Pie 2-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Savoury",
            "src": "/assets/products/BV-MD-SNACK-Steak Pie 2-LEFT.png"
        },
        {
            "name": "Strawberries",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Strawberries-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Fruit/Nuts",
            "src": "/assets/products/BV-MD-SNACK-Strawberries-LEFT.png"
        },
        {
            "name": "Swizzels Drumsticks Squashies",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Swizzels Drumsticks Squashies-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Sweets",
            "src": "/assets/products/BV-MD-SNACK-Swizzels Drumsticks Squashies-LEFT.png"
        },
        {
            "name": "Takeaway (small)",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-TakeawaySM-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Hot Drinks",
            "src": "/assets/products/BV-MD-SNACK-TakeawaySM-LEFT.png"
        },
        {
            "name": "Terry's Chocolate Orange",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Terry's Chocolate Orange-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Terry's Chocolate Orange-LEFT.png"
        },
        {
            "name": "Tikka Masala",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Tikka Masala-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Curry",
            "src": "/assets/products/BV-MD-SNACK-Tikka Masala-LEFT.png"
        },
        {
            "name": "Toffee Crisp",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Toffee Crisp-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Toffee Crisp-LEFT.png"
        },
        {
            "name": "Tofu Veggie Bowl",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Tofu Veggie Bowl-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Salad",
            "src": "/assets/products/BV-MD-SNACK-Tofu Veggie Bowl-LEFT.png"
        },
        {
            "name": "Tuna Sandwich",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Tuna Sandwich-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Sandwich",
            "src": "/assets/products/BV-MD-SNACK-Tuna Sandwich-LEFT.png"
        },
        {
            "name": "Twix",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Twix-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Chocolate",
            "src": "/assets/products/BV-MD-SNACK-Twix-LEFT.png"
        },
        {
            "name": "Tyrells Lightly Salted Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Tyrells Lightly Salted Crisps-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Tyrells Lightly Salted Crisps-LEFT.png"
        },
        {
            "name": "Tyrells Mature Cheddar Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Tyrells Mature Cheddar Crisps-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Tyrells Mature Cheddar Crisps-LEFT.png"
        },
        {
            "name": "Tyrells Veg Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Tyrells Veg Crisps-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Tyrells Veg Crisps-LEFT.png"
        },
        {
            "name": "Vegan Buddha Bowl",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Vegan Buddha Bowl-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Salad",
            "src": "/assets/products/BV-MD-SNACK-Vegan Buddha Bowl-LEFT.png"
        },
        {
            "name": "Waffles",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Waffles-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Breakfast",
            "src": "/assets/products/BV-MD-SNACK-Waffles-LEFT.png"
        },
        {
            "name": "Walkers Baked Cheese & Onion Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Walkers Baked Cheese & Onion Crisps-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Walkers Baked Cheese & Onion Crisps-LEFT.png"
        },
        {
            "name": "Walkers Baked Salt & Vinegar Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Walkers Baked Salt & Vinegar Crisps-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Walkers Baked Salt & Vinegar Crisps-LEFT.png"
        },
        {
            "name": "Walkers Baked Sea Salt Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Walkers Baked Sea Salt Crisps-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Walkers Baked Sea Salt Crisps-LEFT.png"
        },
        {
            "name": "Walkers Beef Monster Munch",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Walkers Beef Monster Munch-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Walkers Beef Monster Munch-LEFT.png"
        },
        {
            "name": "Walkers Cheese & Onion Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-WalkersCheeseOnion-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-WalkersCheeseOnion-LEFT.png"
        },
        {
            "name": "Walkers Cheese and Onion Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Walkers Cheese and Onion Crisps-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Walkers Cheese and Onion Crisps-LEFT.png"
        },
        {
            "name": "Walkers Pickled Onion Monster Munch",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Walkers Pickled Onion Monster Munch-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Walkers Pickled Onion Monster Munch-LEFT.png"
        },
        {
            "name": "Walkers Prawn Cocktail Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Walkers Prawn Cocktail Crisps-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Walkers Prawn Cocktail Crisps-LEFT.png"
        },
        {
            "name": "Walkers Quavers Cheese",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Walkers Quavers Cheese-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Walkers Quavers Cheese-LEFT.png"
        },
        {
            "name": "Walkers Ready Salted Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Walkers Ready Salted Crisps-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Walkers Ready Salted Crisps-LEFT.png"
        },
        {
            "name": "Walkers Salt & Vinegar Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-WalkersSaltVinegar_LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-WalkersSaltVinegar_LEFT.png"
        },
        {
            "name": "Walkers Salt and Vinegar Crisps",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Walkers Salt and Vinegar Crisps-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Walkers Salt and Vinegar Crisps-LEFT.png"
        },
        {
            "name": "Walkers Salt and Vinegar Squares",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Walkers Salt and Vinegar Squares-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Walkers Salt and Vinegar Squares-LEFT.png"
        },
        {
            "name": "Walkers Wotsits Really Cheesey",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Walkers Wotsits Really Cheesey-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Crisps",
            "src": "/assets/products/BV-MD-SNACK-Walkers Wotsits Really Cheesey-LEFT.png"
        },
        {
            "name": "Werthers Original",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Werthers Original-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Sweets",
            "src": "/assets/products/BV-MD-SNACK-Werthers Original-LEFT.png"
        },
        {
            "name": "White Grapes",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-White Grapes-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Fruit/Nuts",
            "src": "/assets/products/BV-MD-SNACK-White Grapes-LEFT.png"
        },
        {
            "name": "Wide bowl fries",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Wide bowl fries-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Fries",
            "src": "/assets/products/BV-MD-SNACK-Wide bowl fries-LEFT.png"
        },
        {
            "name": "Wrapped Sandwich",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Wrapped Sandwich-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Sandwich",
            "src": "/assets/products/BV-MD-SNACK-Wrapped Sandwich-LEFT.png"
        },
        {
            "name": "Yoghurt",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Yoghurt-LEFT.png",
            "backgroundColour": "#fff",
            "category": "Breakfast",
            "src": "/assets/products/BV-MD-SNACK-Yoghurt-LEFT.png"
        },
        {
            "name": "Yogurt Breakfast Bowl",
            "shortDesc": "",
            "longDesc": "",
            "imageUrl": "BV-MD-SNACK-Yogurt Breakfast Bowl-LEFT.png",
            "backgroundColour": "#ffffff",
            "category": "Breakfast",
            "src": "/assets/products/BV-MD-SNACK-Yogurt Breakfast Bowl-LEFT.png"
        }
    ]
}

export const getScaledImage = (imageUrl: string, width: number, skipMiddleware?: boolean) => {

    /*
        width = 
    */

    return skipMiddleware ?`${imageUrl}` : `${imageUrl}?format=webp&quality=50&width=${Math.round(width)}&cache=${getCacheKey()}`;    
}

//No longer used but could be useful
const isEmpty = (values: object) => {
    const keys = Object.keys(values);

    //Check if there's any information in the initial values
    var set = keys.reduce((prev: any, curr: any) => {
        if (values)
            return prev + 1;
        return prev;
    }, 0);

    return set <= 0;
}

const enableSavePrompt = () => {
    window.onbeforeunload = function () {
        return true;
    };
}

const removeSavePrompt = () => {
    window.onbeforeunload = null;
}

//From https://stackoverflow.com/questions/8493195/how-can-i-parse-a-csv-string-with-javascript-which-contains-comma-in-data with some edits
const csvToArray = (text: string) => {
    //var re_valid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
    var re_value = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;

    // Return NULL if input string is not well formed CSV string.
    //if (!re_valid.test(text)) return null;

    return text.split('\n').reduce((arr: any, line: string) => {
        var cols = [];

        line.replace(re_value,
            function (m0, m1, m2, m3) {
                if (m1 !== undefined) cols.push(m1.replace(/\\'/g, "'"));
                else if (m2 !== undefined) cols.push(m2.replace(/\\"/g, '"'));
                else if (m3 !== undefined) cols.push(m3);
                return '';
            });

        if (/,\s*$/.test(line)) cols.push('');

        arr.push(cols);
        return arr;
    }, []);
};

const transformTheme = (theme: any, style: any) => {
    Object.keys(theme).forEach(key => {
        if (typeof (theme[key]) === "string") {
            let isUrl = theme[key].indexOf("url") !== -1;
            let styleIndex = theme[key].indexOf("style.");

            if (styleIndex !== -1) {
                //Extract path from string
                var parts = theme[key].substr(6 + styleIndex);

                //Remove the url bit
                if (isUrl)
                    parts = parts.substr(0, parts.length - 1);

                //Split normally
                parts = parts.split(".");

                if (parts.length > 1) {
                    let value = parts.reduce((prev: any, curr: any) => {
                        if (prev === undefined)
                            return prev;
                        return prev[curr];
                    }, style);

                    if (!value)
                        console.error(`Linked value doesn't exist ${parts.join(" -> ")}`);
                    else {
                        if (value instanceof Object) {
                            theme[key] = isUrl ? `url(${value.data})` : value.data;
                            theme = {
                                ...theme,
                                ...value.style
                            };
                        } else
                            //Put it back together if it's a url
                            theme[key] = isUrl ? `url(${value})` : value;
                    }
                }
            }
        } else if (theme[key] instanceof Object)
            theme[key] = transformTheme(theme[key], style);
    });

    return theme;
};

export {
    randomId,
    isEmpty,
    enableSavePrompt,
    removeSavePrompt,
    csvToArray,
    transformTheme
}